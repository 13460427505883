
import React, { Component } from 'react';
import './about.scss'

export default function () {
  console.log('our story rendering')
  return(
    <div className="about-page">
      <article className="post our-story">
        {/* <h1>Our Story</h1> */}


        <div class="art-article"><h1 >The Healthiest Staple Foods in Japanese Cuisine</h1>
<p ><strong>Miso:</strong> Miso contains many traces of minerals including zinc, manganese and copper, which helps to strengthen the immune system, boost energy and protect bones and blood vessels. It is a rich source of protein. One tablespoon has two grams of protein and just 25 calories.<br /><br /><strong>Seaweed: </strong>Seaweed contains the br /oadest range of minerals of any food—the same minerals found in the ocean and in human blood such as, potassium, calcium, magnesium, iron and iodine.<br /><br /><strong>Natto: </strong>Natto is made from fermented soybeans and is a common br /eakfast food in Japan. It is an excellent source of protein, Vitamin B2 and Vitamin K2, which is useful for osteoporosis. It contains compounds including phytosestrogen, seleniun and others. Nattokinase has been found to prevent and reduce the risk of blood clots, as well as provide heart benefits. Some studies suggest that nattokinase can reduce the risk of heart attacks and strokes.<br /><strong><br />Mushrooms: </strong>Mushrooms are rich in protein, fiber, Vitamin C, B Vitamins, calcium and minerals but they are also renowned for their medicinal properties. These nutritional powerhouses have been found to lower risk of cancer, boost immune function and help balance blood sugar levels, among some of the benefits (reiki, shitake, maitake).<br /> <br /><strong>Edamame: </strong>(Soybeans) is a rich source of carbohydrates, proteins, dietary fiber, omega fatty acids, vitamin A and C, iron and calcium. These are also rich in choline, a compound that blocks fat absorption and br /eaks down fatty deposits.</p>
<p ><strong>Ginger:</strong> Ginger has proven to help lower your cholesterol levels and prevent the formation of blood clots. Contrary to popular belief, ginger—a piquant, isn't a root, it's a stem, which means it contains living compounds that improve your health. Chief among them is gingerol, a cancer suppressor that studies have shown to be particularly effective against that of the colon. Ginger has proven to help lower your cholesterol levels and prevent the formation of blood clots.<br /><br /><strong>Green tea: </strong>Green tea is a rich source of catechin polyphenols, namely epigallocatechin gallate (EGCG) which is a potent antioxidant that appears to be responsible for many of green tea’s health benefits. It has been found to help prevent cancer and heart disease.<br /><br /><strong>Sashimi:</strong> Sashimi offers all of the benefits of fresh fish—an excellent source of protein, omega and other nutrients like selenium, niacin and Vitamin B12, phosphorous, magnesium and Vitamin B6. Because sashimi is consumed raw, it is possible for it to contain parasites, but choosing sashimi-grade fish or dining at a restaurant reduces these risks.</p>
<h2 >Health Benefits of Sushi</h2>
<p><span >For a person with normal health, sushi has many health benefits.</span></p>



<p>All dishes (excluding eel and some fusion style sushi) are low in  saturated fat and high in protein. There may be a slight load in  carbohydrates in thick sushi rolls, but it is negligible for <em><span>nigiri </span></em>sushi  since they are small in amount. High content of fish oil is the main  health factor which promotes a healthy cardiovascular system. The <em><span>hikarimono, </span></em>or  shiny fishes (mackerel, Spanish mackerel, sardine, Pacific Saury)  contain the highest amount of EPA and DHA omega3 fats. These fishes are  also high in vitamin E which is a powerful antioxidant. <em><span>Nori</span></em> contains a great source of minerals found in the ocean and vinegar acts  as an important factor in promoting cell metabolism. People who use  vinegar frequently (to dress salads, blending with soy sauce, or  drinking in small amounts (please refer to rice vinegar in choosing the  best ingredients) have lower percentages of body fat. Unfortunately,  people with type Ι or type ΙΙ diabetes should stay away from sushi, and  stick to <em><span>sashimi</span></em>. Individuals with high blood pressure must limit their use of soy sauce.</p>
<address ><span >Source from:<br /><a href="http://web.archive.org/web/20200420225810/http://www.sushiencyclopedia.com/" target="_blank"><span >www.sushiencyclopedia.com</span></a></span></address></div>

      </article>


    </div>
  )
}
